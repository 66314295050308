import * as React from 'react';
import Seo from '../components/seo';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';

const IndexPage = () => (
  <div>
    <div className="flex justify-center items-center h-screen">
      <StatrysLoader />
    </div>
  </div>
);

export const Head = () => <Seo title="View Your invoice" />;

export default IndexPage;
